<template>
  <b-container
    class="bg-light text-white pb-0 fontStandard wall"
    :style="this['events/handleMinHeight']"
    fluid
  >
    <NavTabs :tabs="tabs" :customs="navTabs">
      <b-container class="bg-blue" :style="handleMinHeight" fluid>
        <transition name="smoothly-transition" mode="out-in">
          <!-- Components Tabs -->
          <Dashboard v-if="currentTab === 'dashboard'" />
          <QrCodeList v-else-if="currentTab === 'qr-codes'" />
          <Informations v-else-if="currentTab === 'personal-informations'" />
          <Password v-else-if="currentTab === 'to-change-the-password'" />
          <Dashboard v-else />
          <!-- Components Tabs -->
        </transition>
      </b-container>
    </NavTabs>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Form from "@/mixins/Form";
import Tabs from "./Account/Tabs";

export default {
  mixins: [Form, Tabs],
  components: {
    NavTabs: () => import("@/components/NavTabs"),
  },
  computed: {
    ...mapState(["events"]),
    ...mapGetters(["events/handleMinHeight"]),
    handleMinHeight() {
      const height = this.events.height - 150;
      return {
        "min-height": parseInt(height) + "px",
      };
    },
    currentTab() {
      return this.$route.params.tab;
    },
    navTabs() {
      return {
        activeBgColor: "#163a58",
        activeColor: "#ffc107",
        inactiveBgColor: "#0c2030",
        inactiveColor: "white",
        borderColor: "#343434",
        hoverColor: "#163a58dd",
      };
    },
  },
  mounted() {
    this.$root.timer_loop();
  },
  beforeDestroy() {
    clearTimeout(this.timer_state);
    this.$root.renewToken();
  },
  methods: {},
};
</script>

