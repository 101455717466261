export default {
  components: {
    Dashboard: () => import("./Dashboard"),
    QrCodeList: () => import("./QrCodeList"),
    Informations: () => import("./Informations"),
    Password: () => import("./Password"),
    View404: () => import("@/components/View404"),
  },
  data() {
    return {
      tabs: [
        { title: "Tableau de bord", to: "dashboard" },
        { title: "Qr Codes", to: "qr-codes" },
        { title: "Info. personnelle", to: "personal-informations" },
        { title: "Mot de passe", to: "to-change-the-password" },
      ],
    };
  },
};
